<section id="main-content" class="clearfix">
    <dx-popup
    [(visible)]="isPopupVisible"
    title="Disclaimer"
    [width]="screenWidth"
    [height]="screenHeight">
    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view 
        [height]="scrollHeight" 
        [width]="scrollWidth" 
        [showScrollbar]="scrollbarMode">
        <div id="scrollview-content">
        <p>  Participation in the Authority’s New York Energy Manager (“<b>NYEM</b>”) program includes access to this NYEM platform which is provided to you (<b>“User”</b>) for the visualization of time series Data (defined below) collected from User in connection with the NYEM program to enable the User to better understand the energy characteristics of its facility and explore opportunities for reducing energy consumption.  Data being collected by the Authority is being used to correlate energy consumption with physical aspects of the building and other facility infrastructure.
                 </p>
        <p>As User, you represent and warrant that: (i) User is authorized to participate in the NYEM program, has received credentials from NYEM, and is responsible for energy usage in the facility, and (ii) User is authorized to grant the rights described herein and bind the owner of the Data to the terms set forth herein.  </p>
    
     <p><b>“Data”</b> refers to data, metadata, data elements, identifiers, data models, data structures, databases, information, files, documents, materials, content, libraries, code, scripts, algorithms, and any items similar to any of the foregoing, that are collected by the Authority in connection with its NYEM program.  <b>“Processed Data”</b> means any data, information, work product, report, model or document produced, created or prepared at any time by the Authority which is a derivative work, compilation, combination, analysis, interpretation, repackaging, or reformatting of the Data. </p>
    <p> By clicking on the link below, User agrees that he/she has fully read, understands and agrees: (i) to use the NYEM platform only for its intended use; (ii) with the provisions regarding the ownership, license and use of the Data set forth below, and (iii) that it will not provide its account login credentials to any third party or allow any third party to access the NYEM platform without the Authority’s prior written consent.</p>
    <p>User hereby grants to the Authority a perpetual, non-exclusive, irrevocable, royalty-free, transferable, sublicensable license to use the Data for any and all purposes, including without limitation, the purpose of: (i) providing the NYEM services, including a right to store, record, backup, transmit, maintain, display and use or process Data to the extent necessary in conducting the NYEM program, (ii) improving the overall NYEM program and program offerings, (iii) quality control and troubleshooting the NYEM program, (iv) creating Processed Data, and (v) any purpose of the Authority. </p>
    <p>The Authority may share the Data with third parties in the following manner:  (i)  Processed Data, as well as aggregated or anonymized Data, may be shared with third party vendors associated with the NYEM program that offer energy-related services;  and (ii)  Processed Data, as well as Data (whether or not aggregated or anonymized) may be shared with energy or utility industry organizations, educational and/or research institutions or laboratories,  other distribution utilities, and similar organizations, for any purpose, including without limitation,  to conduct energy-related studies, to analyze new services, to evaluate traffic on the grid, etc.  </p>
    <p>The Authority shall be the sole owner of all right, title and interest in and to the Processed Data, and reserves all rights, title and interest in and to the Processed Data, including all related intellectual property rights.</p>
    <p>NYEM is a copyright of the New York Power Authority.  The Authority reserves all rights, title and interest in and to the NYEM program and its offerings, including all related intellectual property rights.   </p>
    <p>User’s achievement of energy savings at its facility depends on a number of variables, including but not limited to the accuracy of data provided, weather conditions, facility modifications, operations characteristics and the cost of energy. The Authority makes no representations or warranties of any kind to the User regarding energy savings at the facility as a result of participation in the NYEM program, and any and all representations or warranties are hereby expressly disclaimed by the Authority.</p>
               
            <br />

                       <div id="accbutton"></div>
                        <div id="rejectbutton"></div>
                    </div> 
                </dx-scroll-view>
         <br/>
                <dx-button class="dx-button-default-css"  text="Accept" width="130" height="40" (onClick)="handleAcceptClick()"></dx-button>
                <dx-button class="dx-button-default-css" style="margin-left: 25px;"  text="Reject" width="130" height="40" (onClick)="handleRejectClick()"></dx-button>
                
            </div>
                    </dx-popup>
            </section>
<router-outlet></router-outlet>
