import { Component, HostListener, OnInit } from '@angular/core';
import { DxPopupModule, DxButtonModule, DxTemplateModule } from 'devextreme-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
handleRejectClick() {

  console.log(environment.production); // Logs false for development environment
  window.location.href = environment.NYPAStaticPage;
}
handleAcceptClick() {
  window.location.href = environment.nyemURL;
}
handleClick() {
throw new Error('Method not implemented.');
}

public screenWidth: any;  
public screenHeight: any; 

public scrollWidth: any;  
public scrollHeight: any;



  ngOnInit(): void {

    this.screenWidth = window.innerWidth*0.6;  
    this.screenHeight = window.innerHeight* 0.8; 
    
    this.scrollWidth = this.screenWidth -50;  

    if (window.innerWidth > 300)
    {
      this.scrollHeight =  this.screenHeight - 200; 
    }
    else
    {

    this.scrollHeight =  this.screenHeight - 200; 
  }
    this.isPopupVisible = true;

    console.log("screenWidth:" + this.screenWidth);
    console.log("screenHeight:" + this.screenHeight);
    
    console.log( "scrollWidth:" +this.scrollWidth);
    console.log( "scrollWidth:"+ this.scrollWidth);
    
  }

  @HostListener('window:resize', ['$event'])  
  onResize() {  
    this.screenWidth = window.innerWidth*0.6;  
    this.screenHeight = window.innerHeight* 0.8; 
    
    this.scrollWidth = this.screenWidth -40;  
    if (window.innerWidth > 480)
    {
      this.scrollHeight =  this.screenHeight - 200; 
    }
    else
    {

    this.scrollHeight =  this.screenHeight - 180; 
  }
  }  

  isPopupVisible: boolean = false;
  scrollbarMode = 'always';
  title = 'nyem-redirect';
}




